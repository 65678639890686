@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Roboto_GEOMt';
    font-display: swap;
    src: local("Roboto_GEOMt"),
        url("./assets/fonts/Roboto_GEOMt.eot"),
        url('./assets/fonts/Roboto_GEOMt.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Roboto_GEOMt.woff2') format('woff2'),
        url('./assets/fonts/Roboto_GEOMt.woff') format('woff'),
        url('./assets/fonts/Roboto_GEOMt.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto_GEONus';
    font-display: swap;
    src: local("Roboto_GEONus"),
        url("./assets/fonts/Roboto_GEONus.eot"),
        url('./assets/fonts/Roboto_GEONus.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Roboto_GEONus.woff2') format('woff2'),
        url('./assets/fonts/Roboto_GEONus.woff') format('woff'),
        url('./assets/fonts/Roboto_GEONus.ttf') format('truetype');
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
    width: 5px;
    height: 7px;
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
}

::-webkit-scrollbar-thumb {
    background: #525965;
    border: 0 none #ffffff;
    border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
    background: #525965;
}

::-webkit-scrollbar-thumb:active {
    background: #525965;
}

::-webkit-scrollbar-track {
    background: transparent;
    border: 0 none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: transparent;
}

::-webkit-scrollbar-track:active {
    background: transparent;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

body {
    font-family: "Roboto_GEONus", sans-serif!important;
}

.uppercase {
    font-family: "Roboto_GEOMt", sans-serif!important;
    text-transform: none;
}

.uppercase-transform {
    text-transform: uppercase!important;
}

input[type=file]::file-selector-button {
    height: 100%;
    cursor: pointer;
    border-radius: 8px 0 0 8px;
    border: none;
    transition: opacity 0.3s ease-in;
    background-color: lightgray;
}